// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"

import { initProductAnchorScroller } from "./components/productAnchorScroller";
import { initAccordionAnchorScroller } from "./components/accordionAnchorScroller";
import { initHamburgerScroller } from "./components/hamburgerScroller";
import { initHamburgerPainter } from "./components/hamburgerPainter";
import { initConsentHandler } from "./components/consentHandler";

// Internal imports, e.g:
// import { initSelect2 } from '../components/init_select2';

document.addEventListener('turbo:load', (e) => {
  initProductAnchorScroller();
  initAccordionAnchorScroller();
  initHamburgerScroller();
  initHamburgerPainter();
  initConsentHandler(e);
});


