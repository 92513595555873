const initHamburgerScroller = () => {
  const spamburger = document.getElementById('spamburger');
  const hamburger = document.getElementById('hamburger');
  const navbarTop = document.getElementById('navbar-top');
  const navbarNav1 = document.getElementById('navbarNav1');
  if (spamburger && hamburger && navbarTop) {
    spamburger.addEventListener('click', () => {
      if (window.scrollY < navbarTop.offsetHeight) {
        if (navbarNav1 && !navbarNav1.classList.contains('show')) {
          hamburger.click();
        }
        window.scrollTo(0, navbarTop.offsetHeight);
      } else {
        hamburger.click();
      }
    });
  }
}

export { initHamburgerScroller };
