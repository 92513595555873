const initAccordionAnchorScroller = () => {
  const navbar = document.getElementById('navbar');
  const accordions = document.querySelectorAll('.accordion');
  accordions.forEach((accordion) => {
    const accordionItems = accordion.querySelectorAll('.accordion-collapse');
    accordionItems.forEach((item) => {
      item.addEventListener('shown.bs.collapse', () => {
        const navbarHeight = navbar ? navbar.offsetHeight : 0;
        const scrollOffset = accordion.scrollTop + item.parentNode.offsetTop - navbarHeight - 16;
        window.scrollTo(0, scrollOffset);
      });
      item.addEventListener('hidden.bs.collapse', () => {
        const navbarHeight = navbar ? navbar.offsetHeight : 0;
        const scrollOffset = accordion.parentElement.firstElementChild.offsetTop - navbarHeight - 16;
        window.scrollTo(0, scrollOffset);
      });
    });
  });
};

export { initAccordionAnchorScroller };
