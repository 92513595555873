const paintHamburger = (hamburger, navbarTop, navbarMain) => {
  if (window.scrollY + hamburger.getBoundingClientRect().top + (hamburger.offsetHeight / 2) < navbarTop.offsetHeight) {
    if (navbarTop.classList.contains('bg-manuka-dark') || navbarTop.classList.contains('bg-manuka-green')) {
      hamburger.classList.add('white');
    } else {
      hamburger.classList.remove('white');
    }
  } else {
    if (navbarMain.classList.contains('bg-manuka-dark') || navbarMain.classList.contains('bg-manuka-green')) {
      hamburger.classList.add('white');
    } else {
      hamburger.classList.remove('white');
    }
  }
}

const initHamburgerPainter = () => {
  const spamburger = document.getElementById('spamburger');
  const navbarTop = document.getElementById('navbar-top');
  const navbarMain = document.getElementById('navbar-main');
  if (spamburger && navbarTop && navbarMain) {
    paintHamburger(spamburger, navbarTop, navbarMain);
    if (spamburger && navbarTop) {
      window.addEventListener('scroll', () => {
        paintHamburger(spamburger, navbarTop, navbarMain);
      });
      window.addEventListener('resize', () => {
        paintHamburger(spamburger, navbarTop, navbarMain);
      });
    }
  }
}

export { initHamburgerPainter };
