const initConsentHandler = (e) => {
  window.dataLayer = window.dataLayer || [];
  function gtag() { dataLayer.push(arguments) };
  gtag('consent', 'default', {
    'ad_storage': 'denied',
    'analytics_storage': 'denied'
  });
  gtag('js', new Date());
  gtag('config', 'G-W7VG66S7BX', { 'page_location': e.detail.url });
  const consentWrapper = document.querySelector('.consent-wrapper');
  const marketingSwitch = document.getElementById('marketing-cookies');
  const analyticsSwitch = document.getElementById('analytics-cookies');
  const prefButton = document.getElementById('preferences-button');
  const acceptButton = document.getElementById('accept-all-button');
  if (consentWrapper && marketingSwitch && analyticsSwitch && prefButton && acceptButton) {
    const setConsent = () => {
      localStorage.setItem('cookiesDecided', 'true');
      if (marketingSwitch.checked) {
        localStorage.setItem('acceptMarketing', 'true');
      } else {
        localStorage.setItem('acceptMarketing', 'false');
      }
      if (analyticsSwitch.checked) {
        localStorage.setItem('acceptAnalytics', 'true');
      } else {
        localStorage.setItem('acceptAnalytics', 'false');
      }
    }
    const acceptAll = () => {
      localStorage.setItem('cookiesDecided', 'true');
      localStorage.setItem('acceptMarketing', 'true');
      localStorage.setItem('acceptAnalytics', 'true');
    }
    const updateConsent = () => {
      if (localStorage.getItem('acceptMarketing') == 'true') {
        gtag('consent', 'update', {
          'ad_storage': 'granted'
        });
      } else {
        gtag('consent', 'update', {
          'ad_storage': 'denied'
        });
      }
      if (localStorage.getItem('acceptAnalytics') == 'true') {
        gtag('consent', 'update', {
          'analytics_storage': 'granted'
        });
      } else {
        gtag('consent', 'update', {
          'analytics_storage': 'denied'
        });
      }
      document.dispatchEvent(new Event('updateStorage'));
    }
    const deleteCookies = () => {
      const cookies = document.cookie.split(';');
      cookies.forEach(cookie => {
        const name = cookie.split('=')[0].trim();
        if (name.startsWith('_ga') || name.startsWith('visitor')) {
          document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;`;
        }
      });
    }
    updateConsent();
    prefButton.addEventListener('click', () => {
      deleteCookies();
      setConsent();
      updateConsent();
      consentWrapper.style.display = 'none';
    });
    acceptButton.addEventListener('click', () => {
      acceptAll();
      updateConsent();
      consentWrapper.style.display = 'none';
    });
    if (localStorage.getItem('cookiesDecided') != 'true') {
      consentWrapper.style.display = 'block';
    }
  }
  const cookieButton = document.getElementById('cookie-button');
  if (cookieButton) {
    cookieButton.addEventListener('click', () => {
      consentWrapper.style.display = 'block';
    });
  }
}

export { initConsentHandler };
